.bsrHeader {
  background-color: #0653a0;
  height: 8vh;
  width: 100%;
}
#navBrand {
  color: #ffffff;
  font-size: 1em;
  overflow-x: hidden;
}
.pepsiLogo {
  vertical-align: baseline;
  height: 5vh;
}
.headerTitle {
  margin-left: 1.5vw;
}
#dropdownMenu {
  background-color: #0653a0;
  border: 0;
  box-shadow: none;
}
.profileImage {
  height: 2em;
  width: 2em;
  text-align: right;
  box-shadow: none;
}
.profileImage:hover {
  filter: brightness(150%);
}
.userName {
  color: #000000;
}
.profileDetails {
  font-size: 1rem;
  background-color: #0653a0;
  color: #ffffff;
}
.nav-dropdown {
  color: #fff;
}

@media only screen and (min-width: 768px) {
  #navBrand {
    color: #ffffff;
    font-size: 1.5em;
    overflow-x: hidden;
  }
}
