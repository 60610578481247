.icons {
  height: 1.5em;
  width: 1.5em;
}

.roleandStatusSection {
  display: flex;
  justify-content: center;
  line-height: 3em;
  color: #0653a0;
}

.stepper {
  display: flex;
  justify-content: center;
}

.stepper li {
  list-style-type: none;
  float: left;
  width: 10%;
  position: relative;
  font: normal normal 1rem Segoe UI;
  text-align: center;
}
.stepper li:before {
  content: ' ';
  position: relative;
  line-height: 1rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 1px solid #ddd;
  display: block;
  text-align: center;
  margin: auto;
  background-color: white;
  z-index: 2;
}

.stepper li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 10%;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: 1;
}

.stepper li:first-child:after {
  content: none;
}

.stepper li.partial:before {
  content: ' ';
  line-height: 1rem;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  border: 1px solid #ddd;
  display: block;
  text-align: center;
  margin: 0.6rem auto;
  background-color: white;
}
.stepper li.partialActive:before {
  border: 3px solid #0653a0;
}

.stepper li.active,
li.partialActive {
  color: #0653a0;
  font: normal normal bold 1rem Segoe UI;
}

.stepper li.active:before {
  background-image: url(../../images/RoleAndStatus//tickIcon.png);
  background-repeat: no-repeat;
  background-size: 2em 2em;
}

.stepper .notPrep:after {
  background-color: #df5560;
}

.stepper .prep:after {
  background-color: #df5560;
}

.stepper .reviewed:after {
  background-color: #e78612;
}

.stepper .approved:after {
  background-color: #ffce4d;
}

.stepper .interAccepted:after {
  background-color: #74b7ad;
}

.stepper .accepted:after {
  background-color: #74b7ad;
}

.stepper .submitted:after {
  background-color: #74b7ad;
}

.workflowStatus {
  display: block;
}

.flowChart {
  display: none;
}
@media only screen and (min-width: 768px) {
  .flowChart {
    display: block;
  }
  .workflowStatus {
    display: none;
  }
}
