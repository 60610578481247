.icon {
  height: 2vmin;
  width: 2vmin;
}
.alertDisplay {
  color: #505050;
  font: normal normal 1em Segoe UI;
}

.buttonSection {
  text-align: right;
}

.tableContainer {
  overflow-x: auto;
}
.lineItemHeaderSection {
  display: none;
}
.lineItemAddSection {
  display: block;
}
@media only screen and (min-width: 768px) {
  .lineItemHeaderSection {
    display: block;
  }
  .lineItemAddSection {
    display: none;
  }
}
