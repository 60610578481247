.loadingScreen {
  margin-top: 20%;
  text-align: center;
}

.heading {
  font: normal normal bold 1.5rem Segoe UI;
}

.inputLabels {
  font: normal normal bold 1rem Segoe UI;
  margin-left: 1%;
  margin-top: 1%;
}

.activityLogLink {
  text-align: right;
}

.logOption {
  cursor: pointer;
}

.logOption:hover {
  color: #0653a0 !important;
  filter: brightness(150%);
}

.activityLogIcon {
  height: 1em;
  width: 1em;
}
