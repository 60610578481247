.counterLabel {
  font: normal normal bold 0.7rem Segoe UI;
}

.invalidInput {
  border: 1px solid #ff9966 !important;
}

.Inputlables {
  font: normal normal bold 1rem Segoe UI;
}

/* input:read-only {
  background-color: yellow !important;
} */

.InputReadOnly {
  background-color: yellow;
}

.inputField {
  overflow: hidden;
}
