.tabIcon {
  margin: 2%;
  vertical-align: baseline;
}
.tabTitle {
  margin: 1vmin;
}
.InActiveTab {
  color: #000000;
}
.ActiveTab {
  border-bottom: 0.5vmin solid #0653a0 !important;
  color: #0653a0 !important;
  font-weight: bold;
}
