.icon {
  height: 1rem;
  width: 1rem;
}
.inputLabels {
  font: normal normal bold 0.7rem Segoe UI;
}
.counterLabel {
  font: normal normal bold 0.7rem Segoe UI;
}

.invalidInput {
  background-color: #c2dff9;
  border: 2px solid #ff9966 !important;
  border-radius: 3px;
}

.invalidInput:hover {
  background-color: #deedfb;
  border: 2px solid #ff9966;
}

.inputField {
  overflow: hidden;
}

.buttonSection {
  text-align: right;
}

.actionButton {
  background-color: #fff;
  border: 2px solid #d6d6d6;
  border-radius: 3px;
}
.actionButton:hover {
  background-color: #deedfb;
  border: 2px solid #d6d6d6;
}
@media only screen and (min-width: 768px) {
  .inputLabels {
    display: none;
  }
}
