.navigationArea {
  background-color: #ffff;
  border-bottom: 0.1vw solid #c5c5c5;
  box-shadow: 1px 1px 4px #aaaaaa;
  margin: 0;
}

.reportLinks {
  margin-right: auto;
  padding: 0;
}

.activeTab {
  border-bottom: 0.4vmin solid #0653a0 !important;
  color: #7f7f7f !important;
  font-weight: bold;
}

.excelOption {
  cursor: pointer;
  font-weight: bold;
}

.excelOption:hover {
  color: #0653a0 !important;
  filter: brightness(150%);
}
